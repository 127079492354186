import { CompoundButton, Stack } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import '../App.css'

const params = new URLSearchParams(window.location.search);
let vesselId = params.get("vesselId");

//TODO: Add something to the screen that lets the user know what this site is - As a user they may not know what this is all about - maybe something like, need access to Nexon resources? Click on the buttons to register or sign up
export const Intro: React.FunctionComponent = () => {
    vesselId = params.get("vesselId");
    const navigate = useNavigate();
    return (
    <>
    <Stack className="flex-container-col">
        <Stack className="flex-items-col border">
            <Stack className="top-left-align">
                <footer className="white-text top-left-align">Photo by Scott Connor</footer>
            </Stack>
        </Stack>
        <Stack className="flex-items-col full-height">
            <Stack className="border background full-vertical-height center">
                <a href ='https://rsys.com.au/' target="_blank">
                <img src={require("../Images/rsysLogo.png")} width="100% !important" alt="rsys logo" />
                </a>
                <div className="welcomeText">Crew Registration Portal</div>
                <br/>
                <br/>
                
                <br/>
                <CompoundButton styles={{root:{width:'250px'}}} onClick={() => navigate('/checkin' + "?vesselId=" + params.get("vesselId"))}><h2>Race Day Crew <br/>Confirmation <br/> (SKIPPERS ONLY)</h2></CompoundButton>
                <br/>
                <CompoundButton styles={{root:{width:'250px'}}} onClick={() => window.location.replace(window.location.origin + '/register?vesselId='+ vesselId +'&type=new')}><h2>ADD<br/>Skipper/Crew Details</h2></CompoundButton>
                <br/>                
                <CompoundButton styles={{root:{width:'250px'}}} onClick={() => navigate('/edit_members'+ "?vesselId=" + params.get("vesselId"))}><h2>EDIT<br/>Skipper/Crew Details <br/></h2></CompoundButton>
            </Stack>
        </Stack>
    </Stack>
    </>
    );
}