import { Stack } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HoverButton } from "../components/hoverButton";
import './home.css'

//TODO: Add something to the screen that lets the user know what this site is - As a user they may not know what this is all about - maybe something like, need access to Nexon resources? Click on the buttons to register or sign up
export const Home: React.FunctionComponent = () => {
    const navigate = useNavigate();
    return (
    <>
    <Stack className="flex-container-col full-height">
        <Stack className="flex-items-col border">
        </Stack>
        <Stack className="flex-items-col border background full-height">
            <Stack className="center full-height">
                <a href ={'https://www.rsys.com.au/'}>
                <img src={require("../Images/rsysLogo.png")} width="100% !important" alt="rsys logo" />
                </a>
                <div className="welcomeText">Welcome to crew registration app</div>
                <br></br>
                
                <br></br>
              
            </Stack>
        </Stack>
    </Stack>
    </>
    );
}