import { CompoundButton, DefaultButton, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import './newUsers.css'
import '../App.css';
import { InputBox } from "../components/inputBox";

initializeIcons();

const params = new URLSearchParams(window.location.search);

let vesselId = params.get("vesselId");
let currCrew:any[];
let currSkippers:any[];

export const EditPage: React.FunctionComponent = () => {
    vesselId = params.get("vesselId");
    const [crewData, setCrewData] = useState({vessel:'',crew:[]});
    const [skipperData, setSkipperData] = useState({vessel:'',skippers:[]});

    const [dialogueHidden, setDialogueHiden] = useState(false);
    const [buttonActive, setButonActive] = useState(false);
    const [buttonText, setButtonText] = useState('Lookup');

    useEffect(() => {
        (async function () {
            // const testVesselID = "aa5046d4-7f22-ed11-9db1-000d3ad0aa6a";
            const currSkippers = await apiGetSkippers(vesselId);
            setSkipperData(currSkippers);
            const currCrew = await apiGetCrew(vesselId);
            setCrewData(currCrew);
        })();
    },[]);

    currCrew = crewData.crew;
    currSkippers = skipperData.skippers;
    const navigate = useNavigate();

    const httpFuncAsync = async (type:string) => {
        // if (inputErrorCheck()) {
            // window.location.replace(window.location.origin + "/loading")
        let returnUrl = '';
        let lookupValue = '';
        switch (type) {
            case 'rsys':
                lookupValue = (document.getElementById('RSYSMembernumber') as HTMLInputElement).getElementsByTagName('input')[0].value;
                break;
            case 'sailpass':
                lookupValue = (document.getElementById('AustralianSailingorSailPassnumber') as HTMLInputElement).getElementsByTagName('input')[0].value;
                break;
            default:
                lookupValue = 'User not found';
        }
        returnUrl = await apiLookupUser(lookupValue,type);
        console.log(returnUrl)
        if (!returnUrl.includes('User not found') ) {
            (document.getElementById('errorBox') as HTMLElement).classList.add('invisible');
            window.location.replace(`register?vesselId=${vesselId}&user=${returnUrl}&type=update`); 
        } else {
            console.log('test1');
            (document.getElementById('errorBox') as HTMLElement).classList.remove('invisible');
            setButonActive(false);
            setButtonText('Lookup')
        }
        // }

        // console.log(window.location.origin + '/thank_you?vesselId=' + vesselId)

    }

    return (
        <>
        <Stack className="flex-container-col">
            <Stack className="flex-items-col border">
                <Stack className="top-left-align">
                    <footer className="white-text top-left-align">Photo by Scott Connor</footer>
                </Stack>
            </Stack>
            <Stack className="flex-items-col full-height">
                <Stack className="border background full-vertical-height center">
                    <a href ='https://rsys.com.au/' target="_blank"><img src={require("../Images/rsysLogo.png")} alt="rsys logo" /></a>
                    <h1 className="white-text"><u>Edit Member Details</u></h1>

                    <Stack id="errorBox" tokens={{padding:20}} className="invisible invalid-input">
                        <Stack className="white-text">
                            <h3>User not found, please try again or contact the Sailing Office for assistance.</h3>
                        </Stack>
                    </Stack>

                    <br></br>

                    <h2 className="white-text">For RSYS Members, please enter  <br></br>your member number below:</h2>
                    {InputBox("RSYS Member number","text")} 
                    <br></br>
                    <DefaultButton disabled={buttonActive} text={buttonText} onClick={() =>{
                        setButtonText('Please wait...')
                        setButonActive(true)
                        httpFuncAsync('rsys');
                    }}/>
                    <br></br>
                    <br></br>
                    <h2 className="white-text">If you are not an RSYS member,<br></br> please enter your Australian Sailing<br></br> number or SailPass number below:</h2>
                    <br></br>
                    {InputBox("Australian Sailing or SailPass number","text")} 
                    <br></br>
                    <DefaultButton disabled={buttonActive} text={buttonText} onClick={() =>{
                        setButtonText('Please wait...')
                        setButonActive(true)
                        httpFuncAsync('sailpass');
                    }}/>
                    <br></br> 
                    <br></br> 
                    <Stack className="divdisplay">
                        <CompoundButton styles={{root:{width:'150px'}}} onClick={() => navigate('/intro'+ "?vesselId=" + params.get("vesselId"))}><h3>Cancel</h3></CompoundButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </>
    );

}

const apiGetSkippers = async (vesselId:string|null) => {
    try {
        const response = await fetch('/api/GetSkipper?vesselID='+vesselId, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'} 
        });
        if (!response.ok) throw new Error(`Error! status: ${response.status}`)
        const requestResponse = await response.json();
        return requestResponse;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message)
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

const apiGetCrew = async (vesselId:string|null) => {
    try {
        const response = await fetch('/api/GetCrew?vesselID='+vesselId, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'} 
        });
        if (!response.ok) throw new Error(`Error! status: ${response.status}`)
        const requestResponse = await response.json();
        return requestResponse;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message)
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

const apiLookupUser = async (id:string|null,type:string) => {
    try {
        const response = await fetch(`/api/lookupUser?id=${id}&type=${type}&vesselId=${vesselId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'} 
        });
        if (!response.ok) throw new Error(`Error! status: ${response.status}`)
        // console.log(await response.text());
        // debugger;

        return await response.text();
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message)
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}