import { Stack } from "@fluentui/react"
import './home.css'
import '../App.css'
import { HoverButton } from "../components/hoverButton"
import { useLocation, useNavigate } from "react-router-dom"

import styles from './thankYou.module.scss'
import React from "react"

const params = new URLSearchParams(window.location.search);
let vesselId = params.get("vesselId");

export const ThankYou: React.FunctionComponent = () => {
    vesselId = params.get("vesselId");
    const navigate = useNavigate();
    const userID = (new URLSearchParams(useLocation().search)).get('id');
    httpProvisionPost(userID);
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
                <Stack className="top-left-align">
                    <footer className="white-text top-left-align">Photo by Scott Connor</footer>
                </Stack>
            </Stack>
            <Stack className="flex-items-col border background full-height">
                <Stack className="center full-height">
                    
                    <Stack className="flex-container">
                        <Stack className="flex-items">
                            <img src={require("../Images/logo512.png")} width="100%" alt="rsysLogo" />
                        </Stack>
                        <Stack className="flex-items">
                            <Stack style={{width:'90%',color: 'white'}}>
                                <h3 className={`${styles.pageQuote}`}>Thank you for registering your details</h3>
                            </Stack>
                        </Stack>
                        
                    </Stack>
                    <h3 className={`${styles.pageText } ${styles.pageDescription}`}>Good luck for the race and enjoy the event.</h3>
                    <br></br>
                    <br></br>
                    <Stack>
                        {HoverButton('Back to Home',() => {navigate('/intro?vesselId=' + vesselId)})}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}

const httpProvisionPost = async (userID: string | null) => {
    if (userID !== null) {
        const idData = {id: userID}
        const response = await fetch('https://nexon-fusion-dev-functions.azurewebsites.net/api/Finalise_UserProvisioning?', {
        method: 'POST',
        body: JSON.stringify(idData),
        headers: {'Content-Type': 'application/json'} 
        });
        console.log(response)
        // const requestResponse : any = await response.json();
        // console.log(requestResponse);
        // return requestResponse.ReturnURL;
    }
} 