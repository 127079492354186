import { ProgressIndicator, Stack } from "@fluentui/react"
import './home.css'
import '../App.css'
import React from "react"

export const LoadingPage: React.FunctionComponent = () => {
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
                <Stack className="top-left-align">
                    <footer className="white-text top-left-align">Photo by Scott Connor</footer>
                </Stack>
            </Stack>
            <Stack className="flex-items-col border background full-height">
                <Stack className="center full-height">
                
                    <br></br>
                    <br></br>
                    <br></br>
                    <Stack>
                        <ProgressIndicator label="Transferring your details . . ." styles={{itemName:{color:'white'} }}/>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}