import { Checkbox, CompoundButton, Stack, ICheckboxStyles } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputBox } from "../components/inputBox";
import './newUsers.css'
import '../App.css';

let params = new URLSearchParams(window.location.search);
let currUserId = params.get("user");
let entryType = params.get("type");
let userRole = params.get("role");
let vesselId = params.get("vesselId");

export const Register: React.FunctionComponent = () => {
    params = new URLSearchParams(window.location.search);
    currUserId = params.get("user");
    entryType = params.get("type");
    userRole = params.get("role");
    vesselId = params.get("vesselId");

    const [userData, setUserData] = useState({crewId:currUserId,firstName:'',lastName:''});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [buttonActive, setButonActive] = useState(false);
    const [buttonText, setButtonText] = useState('Submit');

    useEffect(() => {
        (async function () {
            // const testVesselID = "aa5046d4-7f22-ed11-9db1-000d3ad0aa6a";
            if (currUserId != null) {
                const currUser = await apiGetUser(currUserId);
                setUserData(currUser);
                setFirstName(currUser.firstName);
                setLastName(currUser.lastName);
            }
        })();
    },[]);


    const navigate = useNavigate();
    const httpFuncAsync = async () => {
        if (inputErrorCheck()) {
            // window.location.replace(window.location.origin + "/loading")
            const returnUrl: string = await apiSetUser(entryType,getValuesFromTextBoxes(vesselId));
            window.location.replace(`${returnUrl}?vesselId=${vesselId}`); 
        }

        // console.log(window.location.origin + '/thank_you?vesselId=' + vesselId)

        setButonActive(false);
        setButtonText('Submit')
    }

    let currFirstName = (currUserId != null) ? firstName : '';
    let currLastName = (currUserId != null) ? lastName : '';
    
    const skipperCheck = (userRole != null && userRole === 'skipper') ? true : false;
    const crewCheck = (userRole != null && userRole === 'crew') ? true : false;

    const getPageHeading = () => {
        if (entryType === 'new') return "Skipper/Crew Details";
        else if (entryType === 'update') {
            if (userRole === "skipper") return "Update Skipper Details"
            else if (userRole === "crew") return "Update Crew Details"
        }
    }

    const cancelButtonFunc = (type: string | null) => {
        if (type === "update") return navigate('/edit_members'+ "?vesselId=" + params.get("vesselId"));
        else return navigate('/intro'+ "?vesselId=" + params.get("vesselId"));
    }
    
    return (
        <>
        <Stack className="flex-container-col">
            <Stack className="flex-items-col border">
                <Stack className="top-left-align">
                    <footer className="white-text top-left-align">Photo by Scott Connor</footer>
                </Stack>
            </Stack>
            <Stack className="flex-items-col full-height">
                <Stack className="border background full-vertical-height center">
                    <a href ='https://rsys.com.au/' target="_blank"><img src={require("../Images/rsysLogo.png")} alt="rsys logo" /></a>                    
                    <h1 className="white-text">{getPageHeading()}</h1>
                    {InputBox("First Name","text",currFirstName)}
                    {InputBox("Last Name","text",currLastName)}
                    {InputBox("RSYS Member Number","text")}
                    
                    <br></br>
                    <span className="register-screen-text">
                    If not an RSYS member, please enter your Australian Sailing number or SailPass number below. <br/> To find your Australian Sailing number click <a className="link-style" href="https://www.sailing.org.au/member-finder/" target="_blank">here</a> <br/> To get a SailPass click <a className="link-style" href="https://www.revolutionise.com.au/rsys/rego/start/&membership=189374" target="_blank">here</a>
                    </span>
                    <br></br>

                    {InputBox("Australian Sailing or SailPass number","text")}
                    {InputBox("Phone Number","text")}
                    {InputBox("Emergency Contact Name","text")}
                    {InputBox("Emergency Phone Number","text")} 

                    <br></br>
                    <br></br>
                    <h2 className="white-text">Please agree to the <a className="link-style" href="http://www.rsys.com.au/our-club/governance/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></h2>
                    <Stack id="privBoxStack">
                        <Checkbox id="privBox" styles={checkboxStyles} label="Agree to Privacy Policy"/>
                    </Stack>

                    <br></br>
                    <h2 className="white-text">Register as: (please select one or more)</h2>
                    <Stack id="skipperCrewStack" >
                        <Stack id="skipperBoxStack">
                            <Checkbox id='skipperBox' defaultChecked={skipperCheck} styles={checkboxStyles} label="Skipper"/>
                        </Stack>
                        <br></br>
                        <Stack id="crewBoxStack">
                            <Checkbox id='crewBox' defaultChecked={crewCheck} styles={checkboxStyles} label="Crew"/>
                        </Stack>

                    </Stack>
                    <br></br>
                    <Stack className="divdisplay">
                        <CompoundButton styles={{root:{width:'150px'}}} onClick={() => cancelButtonFunc(entryType)}><h3>Cancel</h3></CompoundButton>
                        <CompoundButton styles={{root:{width:'150px'}}} disabled={buttonActive} onClick={() => {
                                setButtonText('Please wait...')
                                setButonActive(true)
                                httpFuncAsync();
                             }}><h3>{buttonText}</h3></CompoundButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </>
    );
}

const checkboxStyles : ICheckboxStyles = {
    text: {
        color: 'white',
        fontSize: 19
    },
    checkbox: {
        borderColor: 'white'
    }

}

type infObjType = {
    crewId:string,
    firstName:string,
    lastName:string,
    memberNumber:string,
    otherNumber: string,
    phone:string,
    nextOfKinName:string,
    nextOfKinPhone:string,
    privacy:boolean,
    safety:boolean,
    vesselId:string,
    skipper:boolean,
    crew:boolean
};

// const errorCheckHelper = (idString: string, isEmail: boolean = false, clearError: boolean = false): boolean => {
const errorCheckHelper = (idString: string, clearError: boolean = false): boolean => {
    const currObj = document.getElementById(idString) as HTMLElement;
    const currInputValue = currObj.getElementsByTagName('input')[0];
    const currClassList = currObj.classList;

    if (clearError) currObj.classList.remove('invalid-input');
    else {
        if (currInputValue.value.length === 0) {
            currClassList.add('invalid-input');
            return false;
        } else currObj.classList.remove('invalid-input');
    }
    
    return true;
}

const checkboxErrorHelper = (idString: string, boxString: string, secondCheck: boolean = false, idString2? : string): boolean => {
    const currBox = document.getElementById(boxString) as HTMLElement;
    let isChecked = getIsChecked(idString);
    const currBoxClassList = currBox.classList;
    if (!secondCheck) {
        if (!isChecked) currBox.classList.add('invalid-input');
        if (isChecked && currBoxClassList.contains('invalid-input')) {
            currBoxClassList.remove('invalid-input')
        }
    } else if (typeof idString2 === 'string') {
        const isChecked2 = getIsChecked(idString2);
        if (!isChecked && !isChecked2) { currBoxClassList.add('invalid-input')}
        if ((isChecked || isChecked2) && currBoxClassList.contains('invalid-input')) {
            currBoxClassList.remove('invalid-input')
        }

        isChecked = isChecked || isChecked2;
    }

    return isChecked;
}

const getIsChecked = (objString:string) => {
    return (document.getElementById(objString) as HTMLInputElement).checked;
}

const checkboxErrorCheck = () => {
    const privAgree = checkboxErrorHelper('privBox','privBoxStack');    
    const skipperCrewCheck = checkboxErrorHelper('skipperBox','skipperCrewStack',true,'crewBox');
    return (privAgree && skipperCrewCheck);
}

const inputErrorCheck = () => {
    const firstNameError = errorCheckHelper('FirstName')
    const lastNameError = errorCheckHelper('LastName')
    const memberError = errorCheckHelper('RSYSMemberNumber')
    const sailpassError = errorCheckHelper('AustralianSailingorSailPassnumber')
    const phoneNumberError = errorCheckHelper('PhoneNumber')
    // const emailError = errorCheckHelper('Email', true)
    const EmergencyNameError = errorCheckHelper('EmergencyContactName')
    const EmergencyNumberError = errorCheckHelper('EmergencyPhoneNumber')
    const checkboxError = checkboxErrorCheck();

    console.log(memberError)
    console.log(sailpassError)

    if (memberError && !sailpassError) {
        errorCheckHelper('AustralianSailingorSailPassnumber',true)
    } else if (sailpassError && !memberError) {
        errorCheckHelper('RSYSMemberNumber',true)
    }

    const memberSailError = memberError || sailpassError;

    if( firstNameError && lastNameError && memberSailError && phoneNumberError && EmergencyNameError && EmergencyNumberError && checkboxError) return true;
    return false;
}

const getValuesFromTextBoxes = (vesselId:string|null): infObjType => {
    
    const response=vesselId || '';
    
    const crewIdValue = currUserId || '';
    const firstNameValue = (document.getElementById('FirstName') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const lastNameValue = (document.getElementById('LastName') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const MemberNumberValue = (document.getElementById('RSYSMemberNumber') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const SailPassnumberValue = (document.getElementById('AustralianSailingorSailPassnumber') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const PhoneNumberValue = (document.getElementById('PhoneNumber') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const EmergencyContactValue = (document.getElementById('EmergencyContactName') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const EmergencyContactPhoneValue = (document.getElementById('EmergencyPhoneNumber') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const skipperValue = getIsChecked('skipperBox');
    const crewValue = getIsChecked('crewBox');
    // const safetyValue = getIsChecked('safetyBox');
    const privacyValue = getIsChecked('privBox');
    const infObj: infObjType = {
        crewId:crewIdValue,
        firstName:firstNameValue,
        lastName:lastNameValue,
        memberNumber:MemberNumberValue,
        otherNumber: SailPassnumberValue,
        phone:PhoneNumberValue,
        nextOfKinName:EmergencyContactValue,
        nextOfKinPhone:EmergencyContactPhoneValue,
        privacy:privacyValue,
        vesselId:response,
        safety:false,
        skipper:skipperValue,
        crew:crewValue
    }

    return infObj;
}

const apiGetUser = async (userId:string|null) => {
    try {
        const response = await fetch('/api/GetUser?id='+userId, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'} 
        });
        if (!response.ok) throw new Error(`Error! status: ${response.status}`)
        const requestResponse = await response.json();
        return requestResponse;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message)
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

const apiSetUser = async (entryType:string | null, infObj:infObjType) => {
    let response : Response;
    if (entryType === "update") {
        response = await fetch('/api/UserEntry', {
            method: 'MERGE',
            body: JSON.stringify(infObj),
            headers: {'Content-Type': 'application/json'} 
        });
    } else {
        response = await fetch('/api/UserEntry', {
            method: 'POST',
            body: JSON.stringify(infObj),
            headers: {'Content-Type': 'application/json'} 
        });
    }

    switch(Math.floor(response.status/100)) {
        case 2:
            return '/thank_you'
        case 4:
        case 5:
            return '/error'
        default:
            break;
    }

    const requestResponse : any = await response.json();
    // requestResponse.inviteRedirectUrl=`window.location.origin + '/thank_you?vesselId=${vesselId}'`
    // console.log(requestResponse)
    // return requestResponse.inviteRedirectUrl;
    return window.location.origin + '/thank_you?vesselId=' + vesselId
}