import './inputBox.sass'
import '../pages/home.css'
import React from 'react';

export const InputBox = (placeholder:string, inputType: string, prefil?: string) => {
    return(
        <>
            <label className="inp" id={placeholder.replace(/\s/g, "")}>
                <input defaultValue={prefil} required type={inputType} placeholder="&nbsp;"/>
                <span className="label">{placeholder}</span>
                <span className="focus-bg"></span>
            </label>
        </>
    );
}

