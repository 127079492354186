import { Checkbox, ChoiceGroup, CompoundButton, IChoiceGroupOption, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import './newUsers.css'
import '../App.css';

initializeIcons();

const params = new URLSearchParams(window.location.search);

let vesselId = params.get("vesselId");
let currCrew:any[];
let currSkippers:any[];

export const CheckIn: React.FunctionComponent = () => {
    vesselId = params.get("vesselId");
    const [crewData, setCrewData] = useState({vessel:'',crew:[]});
    const [skipperData, setSkipperData] = useState({vessel:'',skippers:[]});

    const [buttonActive, setButonActive] = useState(false);
    const [buttonText, setButtonText] = useState('Submit');

    useEffect(() => {
        (async function () {
            // const testVesselID = "aa5046d4-7f22-ed11-9db1-000d3ad0aa6a";
            const currSkippers = await apiGetSkippers(vesselId);
            setSkipperData(currSkippers);
            const currCrew = await apiGetCrew(vesselId);
            setCrewData(currCrew);
        })();
    },[]);


    currCrew = crewData.crew;
    currSkippers = skipperData.skippers;
    const navigate = useNavigate();
    const currDateString = getDateStringFromDate(new Date());

    const httpFuncAsync = async () => {
        if (inputErrorCheck()) {
            const infObj:infObjType = {
                vesselId:vesselId || '',
                skipperId:getCheckedRadio(currSkippers)[0].skipperId,
                crew:getChecked(currCrew)
            }
            const returnUrl: string = await apiCheckIn(infObj);
            window.location.replace(`${returnUrl}?vesselId=${vesselId}`);
       }

        setButonActive(false);
        setButtonText('Submit')
    }

    return (
        <>
        <Stack className="flex-container-col">
            <Stack className="flex-items-col border">
                <Stack className="top-left-align">
                    <footer className="white-text top-left-align">Photo by Scott Connor</footer>
                </Stack>
            </Stack>
            <Stack className="flex-items-col full-height">
                <Stack className="border background full-vertical-height center">
                    <a href ='https://rsys.com.au/' target="_blank"><img src={require("../Images/rsysLogo.png")} alt="rsys logo" /></a>
                    <h1 className="white-text"><u>Crew registration</u></h1>
                    <h2 className="white-text">Today's Date: <strong>{currDateString}</strong></h2>
                    <h2 className="white-text"><u>Select Skipper</u></h2>
                    {displayMembersRadio(currSkippers,"skipper")}
                    <br></br>
                    <h2 className="white-text"><u>Select Crew</u></h2>
                    <Stack className="left-justify">
                        {displayMembers(currCrew,"crew")}   
                    </Stack>
                    <br></br>
                    <Stack className="divdisplay">
                        <CompoundButton styles={{root:{width:'150px'}}} onClick={() => navigate('/intro'+ "?vesselId=" + params.get("vesselId"))}><h3>Cancel</h3></CompoundButton>
                        <CompoundButton styles={{root:{width:'150px'}}} disabled={buttonActive} onClick={() => {
                                setButtonText('Please wait...')
                                setButonActive(true)
                                httpFuncAsync();
                             }}><h3>{buttonText}</h3></CompoundButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </>
    );

    function displayMembersRadio(memberList:any[],role:string): React.ReactNode {
        const options: IChoiceGroupOption[]|undefined = Array.from(memberList.map((value:any)=> {
            return {id:value.crewId, key:value.skipperId, text:value.firstName + " " + value.lastName, styles: { root: { color: 'white', fontSize: 19 }}, onRenderField: (props,render)=> {
                return(
                    <Stack>
                        {render!(props)}
                    </Stack>
                )
            }}
        }));
        return (
            <ChoiceGroup id="skipperChoiceGroup" defaultSelectedKey={undefined} options={options}/>
        )
    }
    
    function displayMembers(memberList:any[],role:string): React.ReactNode {
        return React.Children.toArray(
             memberList.map((value: any, index) => {
                return (
                    <>
                    <Stack>
                        <Checkbox id={value.crewId} label={value.firstName + " " + value.lastName} styles={{checkbox:{borderColor:'white'}, text: { color: 'white', fontSize: 19 } }} />
                    </Stack>
                    <br></br>
                    </>
                );
            }),
        )
    }
}

const getChecked = (crewList: any[]) => {
    return crewList.filter((value) => {
        const selected = document.querySelector('[id*="' + value.crewId + '"]:checked');
        if (selected != null) return selected;
    })
}

const getCheckedRadio = (crewList: any[]) => {
    return crewList.filter((value) => {
        const selected = document.querySelector('[id*="' + value.skipperId + '"]:checked');
        if (selected != null) return selected;
    })
}

const apiGetSkippers = async (vesselId:string|null) => {
    try {
        const response = await fetch('/api/GetSkipper?vesselID='+vesselId, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'} 
        });
        if (!response.ok) throw new Error(`Error! status: ${response.status}`)
        const requestResponse = await response.json();
        return requestResponse;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message)
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

const apiGetCrew = async (vesselId:string|null) => {
    try {
        const response = await fetch('/api/GetCrew?vesselID='+vesselId, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'} 
        });
        if (!response.ok) throw new Error(`Error! status: ${response.status}`)
        const requestResponse = await response.json();
        return requestResponse;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message)
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

type infObjType = {
    vesselId:string,
    skipperId:string,
    crew:string[]
};

const apiCheckIn = async (infObj:infObjType) => {
    const response = await fetch('/api/CheckIn', {
        method: 'POST',
        body: JSON.stringify(infObj),
        headers: {'Content-Type': 'application/json'} 
    });

    switch(Math.floor(response.status/100)) {
        case 2:
            return '/thank_you'
        case 4:
        case 5:
            return '/error'
        default:
            break;
    }

    const requestResponse : any = await response.json();
    requestResponse.inviteRedirectUrl="window.location.origin + '/thank_you'"
    return requestResponse.inviteRedirectUrl;
}

const inputErrorCheck = () => {
    console.log(getCheckedRadio(currSkippers))
    console.log(getChecked(currCrew))

    const selectedSkippers = getCheckedRadio(currSkippers);
    const skipperObj = document.getElementById("skipperChoiceGroup") as HTMLElement;
    if (selectedSkippers.length <= 0 && typeof selectedSkippers !== "undefined") {
        skipperObj.classList.add('invalid-input')
        return false;
    } else skipperObj.classList.remove('invalid-input')
    return true
}

function getDateStringFromDate(date: Date) {
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
}